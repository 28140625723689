<template>
    <div>
        <div class="embed-resposive-item">
            <iframe src="https://docs.google.com/document/d/e/2PACX-1vR2H8ilY7dfgzaSz2STNCRwhOBEdAs8J3UZqvPGNZaz67FzrzRe3mD6vcnrFIjLpFKLcl9CrEXF_g9x/pub?embedded=true"
            style="width:100%;height:1200px;border:0px;background-color:transparent;" frameborder="0" allowtransparency="true" scrolling="no"
            ></iframe>
        </div>
    </div>
</template>
<script >

export default {
    name: 'overview'
}
</script>